import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons/faPhoneAlt";

import logo from 'assets/images/Logo_BHI.svg';
import { getTranslate } from 'utils/translate';
import './styles.scss';

const Header = ({ language }) => {
  return (
    <div className="header">
      <div className="container">
      <img src={logo} alt="BHI" className="header__logo" />
        <div className="header__contacts">
          <a href="tel:+49092518708720" className="header__contacts-item t--green">
            <FontAwesomeIcon icon={faPhoneAlt} className="header__contacts-icon" />
            {getTranslate(language, 'header.hotline')}
            <br /> +49 (0) 9251 87087 20
          </a>
        </div>
        <h1 className="page-title">{getTranslate(language, 'header.title')}</h1>
      </div>
    </div>
  )
};

export default Header;
