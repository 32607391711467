import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Header from "components/Header";
import Footer from "components/Footer";
import { getAllPrintItems, getPrintItem } from "actions/Items";
import PrintList from "pages/PrintList";
import Orders from "pages/OrdersList";
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  Switch,
  Route,
} from "react-router-dom";
import {get} from 'lodash';

class Main extends Component {
  state = {
    printItems: []
  };

  componentDidUpdate(prevProps) {
    if (prevProps.printItems !== this.props.printItems) {
      this.setState({
        printItems: this.props.printItems
      })
    }
  }

  render() {
    const { language, printItems, history } = this.props;

    return (
      <div className='page'>
        <Helmet>
          <html lang={language} />
        </Helmet>
        <Header language={language} email={get(printItems, 'clerk.mail')}/>
        <div className='content'>
          <Switch>
            <Route path="/orders/:id" component={Orders}/>
            <Route exact path="/:id" component={PrintList}/>
            {/*<PrintList printFileItems={printItems} currentItem={currentItem} getPrintItem={getPrintItem}/>*/}
          </Switch>
          <ToastContainer />
        </div>
        
        <Footer language={language}/>

      </div>
    );
  }
}

const mapStateToProps = ({ Items, Navigation }) => ({
  printItems: Items.printItems,
  currentItem: Items.item,
  language: Items.language,
  currentNavigationTab: Navigation.currentNavigationTab
});

const mapDispatchToProps = dispatch => ({
  getAllPrintItems: () => dispatch(getAllPrintItems.request()),
  getPrintItem: (id) => dispatch(getPrintItem.request(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));

